@import "global/fonts";
@import "global/variables";
@import "global/mixin";
@import "global/normalise";
@import "global/colors";
@import "global/typography";

body {
    @import "global/images";
    @import "global/buttons";
    @import "global/header";
    @import "global/footer";
    @import "templates/global-elements-colour";
    splide {
        @import "global/splide/splide.min";
        @import "global/splide/splide-core";
    }
    @import "global/splide/splide";
    @import "global/windowPopup";
    @import "global/breadcrumb";
    z-index: 1;
    .nav-universes {
        .nav-universe {
            &.mm-close  {
            }
        }
    }

    [data-temp] {
        display: none;
    }
    #footer {
        form {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .ltu-search-fields {
                width: 100%;
                .twitter-typeahead {
                    width: 100%;
                }
            }
        }
    }
    main {
        &.main {
            min-height: 60vh;
            @media only screen and (max-width: $mobile) {
                padding-top: 0px;
            }
            .global-elements-wrapper {
                width: 80%;
                margin: auto;
                table {
                    width: 80%;
                    margin: 20px auto;
                    border: 1px solid #ccc;
                    @media only screen and (max-width: $tablet_max)   {
                        width: 100%;
                    }
                }
            }
            .container {
                padding: 20px 10px 50px;
            }
        }
        &[header-version="Transparent"] {
            &.main {
                padding-top: 0px;
            }
        }
    }
// Temp CSS
    &.page-teams {
        .WYSIWYGcontent__wrapper {
            .WYSIWYGcontent--wrapper {
                &--container {
                    margin-bottom: 0px;
                    .bx-item {
                        &--image {
                            width: 300px;
                            height: auto;
                            border-radius: 50%;
                            overflow: hidden;
                            margin: 0 auto;
                        }
                        &--content {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .bx-content-container {
        width: 80%;
        margin: 50px auto;
        @media only screen and (max-width: $mobile) {
            margin: 0px auto;
            width: 95%;
        }
        * {
            text-align: center;
            width: 100%;
            max-width: none;
        }
        h2, h3, h4, h5, h6 {
            margin-top: 0;
        }
    }
    nav header.header-nav .nav-list-main:last-child .item:first-child::before,
    nav header.header-nav.mobile-nav-show .nav-list-main.mobile .mobile-navlist-wrapper .mobile-nav-list-container .mobile-nav-list:last-child .item-links:first-child {
        border: none;
    }
    footer[data-footer] .footer-wrapper .footer-category-wrapper .footer-category-item:last-child svgs[data-svg=icon-logo-white],
    footer[data-footer] .footer-wrapper .mobile.footer-logo-wrapper:after {
        border-bottom: none;
    }
}
