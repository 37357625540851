.sub-title {
    margin-top: 50px;
    @media only screen and (min-width: $tablet_min) and (max-width: $tablet_max)   {
        width: 95%;
        padding: 0;
        margin: 35px auto 20px;
        max-width: unset;
    }
    @media only screen and (max-width: $mobile) {
        width: 95%;
        margin: 35px auto 20px;
    }
}
.ds-link {
    &-list {
        /* Tablet layout */
        @media only screen and (min-width: $tablet_min) and (max-width: $tablet_max)   {
            width: 95%;
            margin: 0 auto;
        }
        @media only screen and (max-width: $mobile) {
            width: 95%;
            margin: 0 auto;
            grid-template-columns: 1fr;
        }
        &__info {
            @include flex_row;
            align-items: center;
            svgs {
                margin-left: 10px;
                width: 24px;
                height: 24px;
            }
        }
    }
}
