body {
    &.body-lock-scroll { overflow: hidden; }

    .bx-flex { display: flex;
        &.bx-flex-row { flex-direction: row; }
        &.bx-flex-column { flex-direction: column; }
        &.flex-start { justify-content: flex-start; }
        &.flex-end { justify-content: flex-end; }
        &.center { justify-content: center; }
        &.space-between { justify-content: space-between; }
        &.space-around { justify-content: space-around; }
        &.space-evenly { justify-content: space-evenly; }
        &.align-top { align-items: flex-start; }
        &.align-center { align-items: center; }
        &.align-bottom { align-items: flex-end; }
        &.flex-start { align-items: flex-start; }
        &.flex-wrap { flex-wrap: wrap; }
        &.flex-nowrap { flex-wrap: nowrap; }
        &.flex-wrap-reverse { flex-wrap: wrap-reverse; }
    }
    .bx-flex-container { display: flex; flex-direction: row; flex-wrap: wrap; }
    .bx-flex-center { display: flex; justify-content: center; align-items: center; }
    .bx-col-1 { width: 100%; }
    .bx-col-2 { width: 50%; }
    .bx-col-3 { width: 33.33%; }
    .bx-col-4 { width: 25%; }
    .bx-col-10 { width: 10%; }
    .bx-col-20 { width: 20%; }
    .bx-col-30 { width: 30%; }
    .bx-col-40 { width: 40%; }
    .bx-col-60 { width: 60%; }
    .bx-col-70 { width: 70%; }
    .bx-col-80 { width: 80%; }
    .bx-col-90 { width: 90%; }

    .align-right { text-align: right; }
    .align-left { text-align: left; }
    .align-center { text-align: center; }
    .align-middle { vertical-align: middle; }
    .align-top { vertical-align: top; }
    .align-bottom { vertical-align: bottom; }
    .float-right { float: right; }
    .float-left { float: left; }

    .animated, svg {
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
    }
    .animated-linear {
        transition: all 0.1s linear;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        -o-transition: all 0.1s linear;
    }

    .hide { display: none; }
    .show, .transitionIn { display: inherit; }
    .enabled { pointer-events: inherit; }
    .disabled { pointer-events: none; }
    .btn, .btn-cta {
        cursor: pointer;
        -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        &--blue {
            background-color: var(--cerulean);
            color: var(--white);
            span {
                -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            }
            &:hover {
                background-color: var(--darkblue);
                color: var(--white);
                span {
                    color: var(--grey);
                }
            }
        }
        &:hover {
            text-decoration: underline;
            transform: translateY(-4px);
            box-shadow: 0 4px 4px rgba(0,0,0,.3);
        }
    }
    .btn-linkfull {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .bg-background.dark { width: 100vw; height: 100vh; top: 0; left: 0; background: rgba(0, 0, 0, 0.8); position: fixed; opacity: 0;
        -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
    }
    .show .bg-background.dark { width: 100vw; height: 100vh; opacity: 1; }

    /* Scroll Detect */
    .data-scroll-detect {
        opacity: 0;
        margin-top: 100px;
        -webkit-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -moz-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -ms-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);

        &.scroll-detected-toggle.scroll-detected, &.scroll-detected-toggle.scroll-detected-passed {
            opacity: 1; margin-top: 0px;
        }
    }
    /* End Scroll Detect */

    @keyframes expend {
        0% { opacity: 0; height: 1px; overflow: hidden; }
        100% { opacity: 1; height: auto; overflow: auto; }
    }

/* Collapse */
    h1, h2, h3, h4, h5, h6, p, span {
        &.btn-collapse {
            margin: 0px 0px 10px;
        }
    }
    .btn-collapse {
        cursor: pointer;
    }
    .data-collapse {
        height: auto;
        overflow: hidden;
        -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        .content {
            margin-top: -300px; display: block; height: 200px; overflow: hidden;
            -webkit-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -moz-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -ms-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        }
        &.collapsed {
            height: auto;
            .content {
                margin-top: 0%;
                height: auto;
            }
        }
    }
/* End Collapse */
}
