body {
    .CardPromo__wrapper {
        .CardPromo {
            &--wrapper {
                &--container {
                    padding: 60px 0;
                    position:  relative;
                    @media only screen and (max-width: $mobile) {
                        padding: 0px 0;
                    }
                    .bx-item {
                        &--image {
                            line-height: 0;
                            img {
                                border-radius: 0;
                                &[bg-img] {
                                    display: none;
                                }
                            }
                        }
                        &--content {
                            position:  absolute;
                            top: 50%;
                            left: 0%;
                            transform: translate(0%, -50%);
                            margin-left: 60px;
                            width: 40%;
                            padding: 70px 50px;
                            border-radius: 40px;
                            background-color: $white;
                            h1, h2, h3, h4, h5, h6 {
                                margin-top: 0;
                            }
                            .title {
                                margin-bottom: 30px;
                            }
                            .content {
                                margin-bottom: 30px;
                            }
                            .btn-wrapper {
                                .ds-btn {
                                    @include flex_row;
                                    justify-content: space-between;
                                    width: fit-content;
                                    * {
                                        pointer-events: none;
                                    }
                                    svgs {
                                        margin-left: 10px;
                                        svg {
                                            path {
                                                stroke: $red;
                                            }
                                        }
                                        &[data-svg="icon-chevron-right"] {
                                            display: inherit;
                                        }
                                        &[data-svg="icon-play"] {
                                            display: none;
                                        }
                                    }
                                }
                                &[btn-style="play"] {
                                    .ds-btn {
                                        svgs {
                                            &[data-svg="icon-chevron-right"] {
                                                display: none;
                                            }
                                            &[data-svg="icon-play"] {
                                                display: inherit;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &[txt-pos="right"] {
                        .bx-item {
                            &--content {
                                left: auto;
                                right: 0%;
                                margin-right: 60px;
                            }
                        }
                    }
                    &[img-style="float"] {
                        max-width: $ContainerMaxWidth;
                        margin: auto;
                        .bx-item {
                            &--image {
                                img {
                                    &[bg-img] {
                                        display: inherit;
                                        opacity: 0;
                                    }
                                    &[img-primary] {
                                        position: absolute;
                                        top: 50%;
                                        left: 0%;
                                        width: 68%;
                                        border-radius: 30px;
                                        margin-left: 0px;
                                        transform: translate(0%, -50%);
                                    }
                                }
                            }
                            &--content {
                                width: 500px;
                                margin-right: 0px;
                            }
                        }
                        &[img-pos="right"] {
                            .bx-item {
                                &--image {
                                    img {
                                        &[img-primary] {
                                            left: auto;
                                            right: 0%;
                                            width: 68%;
                                            border-radius: 30px;
                                            margin-left: 0px;
                                            transform: translate(0%, -50%);
                                        }
                                    }
                                }
                                &--content {
                                    margin-left: 0px;
                                    left: 0%;
                                }
                            }
                        }
                    }
                    &[img-style="small"] {
                        @include flex_row;
                        justify-content: space-between;
                        .bx-item {
                            &--image {
                                width: 40%;
                                img {
                                    border-radius: 20px;
                                    &[bg-img] {
                                        display: none;
                                    }
                                    &[img-primary] {
                                        position: inherit;
                                        margin-left: 80px;
                                        margin-right: 0px;
                                    }
                                }
                            }
                            &--content {
                                position: inherit;
                                transform: translate(0%, 0%);
                                top: auto;
                                left: auto;
                                margin: 0px;
                                .title {
                                    color: $red;
                                }
                            }
                        }
                        &[img-pos="right"] {
                            flex-direction: row-reverse;
                            .bx-item {
                                &--image {
                                    margin-right: 80px;
                                    img {
                                        &[img-primary] {
                                            margin: 0px;
                                        }
                                    }
                                }
                                &--content {
                                    margin-left: 80px;
                                    left: 0%;
                                }
                            }
                        }
                    }
                    @media only screen and (max-width: $tablet_max)   {
                        &[CardPromo][img-style][img-pos] {
                            @include flex_column;
                            .bx-item {
                                width: 100%;
                                &--image {
                                    img {
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        right: auto;
                                        border-radius: 0;
                                        transform: none;
                                        margin: 0;
                                        &[img-primary] {
                                            width: 100%;
                                        }
                                        &[bg-img] {
                                            display: none;
                                        }
                                    }
                                }
                                &--content {
                                    margin: 0;
                                    position: relative;
                                    border-radius: 0;
                                    top: 0;
                                    left: 0;
                                    right: auto;
                                    transform: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
