body {
    main {
        &.main {
            .ds-page-content {
                .Accordions__wrapper {
                        padding: 0;
                }
            }
        }
    }
    .Accordions__wrapper {
        padding: 0;
        .item-accordions {
            padding-top: 20px;
            max-width: 1280px;
            @media only screen and (max-width: $tablet_max) {
                width: 95%;
                margin: auto;
            }
            .title {
                color: $black;
                position: relative;
            }
            .content {
                padding: 35px 0px 35px 35px;
                * {
                    color: $black;
                    margin-top: 0;
                    max-width: none;
                }
                .btn {
                    color: $red;
                    @include fontsize(16);
                    font-weight: 700;
                    line-height: 24px;
                    border: 1px $red solid;
                    border-radius: 30px;
                    padding: 13px 24px;
                    justify-content: center;
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: fit-content;
                    margin-top: 22px;
                    &:hover {
                        text-decoration: underline;
                    }
                    svgs {
                        margin-left: 20px;
                        svg {
                            path {
                                stroke: $red;
                            }
                        }
                    }
                }
            }
            .btn-collapse {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px 0px 0px 35px;
                height: 50px;
                max-width: none;
                border-bottom: 0.50px $grey solid;
                &::before, &::after {
                    -webkit-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                    -moz-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                    -ms-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                    transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                }

                &::before {
                    content: ' ';
                    width: 20px;
                    height: 1px;
                    border-bottom: 2px  solid $black;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    transform: translate(0%, -50%);
                }
                &::after {
                    content: ' ';
                    width: 16px;
                    height: 16px;
                    border-right: 2px  solid $black;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0%;
                    transform: translate(-50%, -50%);
                    opacity: 0;
                }
                &.active {
                    &::after {
                        opacity: 1;
                        left: 2px;
                    }
                    &::before {
                        width: 16px;
                        left: 2px;
                    }
                }
                &:hover {
                    color: $red;
                    text-decoration: underline;
                    &::before {
                        border-bottom: 2px  solid $red;
                    }
                    &::after {
                        border-right: 2px  solid $red;
                    }
                }
            }
            &[version="small"] {
                .title {
                    @include fontsize(16);
                }
            }
            &[version="large"] {
                .title {
                    @include fontsize(26);
                }
            }
        }
    }
}
