body {
    .BannerCentered__wrapper {
        .BannerCentered {
            &--wrapper {
                position: relative;
                .bx-item {
                    &--image {
                        filter: brightness(0.6);
                        line-height: 0;
                        background-size: cover;
                        @media only screen and (max-width: $mobile) {
                            @include elm_center;
                            width: 100%;
                            height: 100%;
                        }
                        img {
                            border-radius: 0;
                            width: 100%;
                            @media only screen and (max-width: $mobile) {
                                display: none;
                            }
                        }
                    }
                    &--content {
                        &[banner-content] {
                            background-color: $black;
                            @include flex_center;
                            padding: 80px 0;
                        }
                        &[banner-image] {
                            @include elm_center;
                            background: none;
                            @media only screen and (max-width: $mobile) {
                                padding: 80px 0;
                                width: 100%;
                                margin: auto;
                                left: 0;
                                position: relative;
                                transform: translate(0%, 0%);
                            }
                        }
                        h2, h3, h4, h5, h6 {
                            margin-top: 0;
                            margin-bottom: 30px;
                        }
                        * {
                            color: $white;
                            text-align: center;
                            @media only screen and (max-width: $mobile) {
                                width: 95%;
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
