.window-popup-wrapper {
    * {
        -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
    }
    .popup-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        [btn-bg] {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $black;
            opacity: 0.6;
        }
        .content-container {
            background: $white;
            width: 50%;
            Height: 50%;
            padding: 20px 30px;
            @include elm_center;
            border-radius: 40px;
            opacity: 1;
            position: relative;
            .title {
                color: $persianGreen;
                @include fontsize(32);
                font-family: Knockout;
                font-weight: 365;
            }
            .btn-close {
                position: absolute;
                width: 14px;
                height: 14px;
                top: 15px;
                right: 30px;
                cursor: pointer;
                svgs {
                    svg {
                        path {
                            fill: $black;
                        }
                    }
                }
                &:hover {
                    svgs {
                        svg {
                            path {
                                fill: $red;
                            }
                        }
                    }
                }
            }
        }
    }
    &.transitionOut {
        [btn-bg], .content-container {
            opacity: 0;
        }
    }
    &.transitionIn {
        [btn-bg], .content-container {
            opacity: 0;
        }
    }
}
