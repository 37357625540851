// Custom Mixins
@mixin fontsize($pixelsize, $root: 16, $unit: rem) {
    font-size: ($pixelsize / $root) + $unit;
}
@mixin flex_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@mixin flex_row {
    display: flex;
    flex-direction: row;
}
@mixin flex_row_wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
@mixin flex_row_nowrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
@mixin flex_column {
    display: flex;
    flex-direction: column;
}
@mixin elm_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin lineheight($pixelsize, $root: 16, $unit: rem) {
    line-height: ($pixelsize / $root) + $unit;
}

@mixin sub_link {
    display: inherit;
    padding: 20px 15px;
    width: 205px;
    opacity: 1;
    li, a {
        opacity: 1;
        height: auto;
    }
}
@mixin mobileBorderBottom {
    &:after {
        content: '';
        border-bottom: 1px solid $white;
        position: absolute;
        width: 93%;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
@mixin mobileBreadcrumbs {
    .mobile-breadcrumbs {
        .breadcrumb {
            padding: 0 0 20px;
            .item {
                *, &.active, &::after {
                    color: $white;
                }
            }
        }
    }
}
@mixin bgClick {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
@mixin searchForm {
    @include flex_row;
    justify-content: space-between;
    align-items: center;
    background-color: $lightgrey2;
    width: fit-content;
    border: none;
    padding: 0 0 0 20px;
    margin: 0 auto;
    line-height: 0;
    border-radius: 30px;
    min-width: 630px;
    position: relative;
    @media only screen and (max-width: $mobile) {
        width: 95%;
        min-width: auto;
        margin-bottom: 35px;
    }
    label {
        display: none;
    }
    input {
        border: none;
        background-color: transparent;
        width: 100%;
        &:focus{
            outline: none;
        }
    }
    button {
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        .btn-group {
            @include flex_row;
            flex-wrap: nowrap;
            padding: 18px 24px;
            margin: 0;
            border: none;
            .txt {
                margin-right: 20px;
                display: inline-block;
                @media only screen and (max-width: $mobile) {
                    display: none;
                }
            }
        }
    }
    svgs {
        &[data-svg="icon-x-circle"] {
            @include flex_center;
            margin: 0 10px 0 0;
            cursor: pointer;
            display: none;
            width: 28px;
            height: 28px;
            * {
                pointer-events: none;
            }
        }
    }
    &.active {
        svgs {
            &[data-svg="icon-x-circle"] {
                display: inherit;
            }
        }
    }
    .funnelback-suggest--wrapper {
        position: absolute;
        top: calc(100% + 2px);
        left: 50%;
        transform: translate(-50%, 0%);
        background-color: $white;
        width: 95%;
        height: auto;
        z-index: 1000;
        * {
            -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        }
        &-contents {
            @include flex_column;
            width: 95%;
            width: 100%;
            background-color: $lightgrey;
            padding: 0;
            .txt, a {
                color: $black;
                text-decoration: none;
            }
            .item {
                width: 100%;
                height: 30px;
                display: inline-block;
                line-height: 1.4;
                display: flex;
                align-items: center;
                padding: 20px 30px;
                cursor: pointer;
                &:hover {
                    background-color: $grey;
                }
            }
        }
    }
}
