body {
    .Banner-Hero__wrapper {
        &[elem-loading] {
            .ds-hero-promo {
                opacity: 0;
            }
        }
        .ds-hero-promo {
            opacity: 1;
            height: auto;
            padding-bottom: 0;
            -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -ms-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            .ds-item {
                &.ds-hero-promo {
                    &__gradient-overlay {
                        @media only screen and (max-width: $mobile) {
                            display: none;
                        }
                    }
                    &__image {
                        line-height: 0;
                        margin: 0px;
                        padding: 0px;
                        position: inherit;
                        padding-bottom: 0;
                        height: auto;
                        @media only screen and (min-width: $tablet_min) and (max-width: $tablet_max) {
                            height: 50vw;
                            overflow: visible;
                        }
                        @media only screen and (max-width: $mobile) {
                            padding: 0;
                            height: auto;
                            margin: 0;
                            padding: 0;
                            position: relative;
                        }
                        img {
                            border-radius: unset;
                            position: relative;
                            margin: 0;
                            padding: 0;
                            &.main-image {
                                top: 0;
                                transform: none;
                                &.desktop {
                                    display: inherit;
                                }
                                &.mobile {
                                    display: none;
                                }
                                @media only screen and (min-width: $tablet_min) and (max-width: $tablet_max) {
                                    max-width: none;
                                    position: absolute;
                                    top: 0%;
                                    left: 50%;
                                    right: auto;
                                    transform: translate(-50%, 0%);
                                    &.desktop {
                                        width: 150%;
                                    }
                                }
                                @media only screen and (max-width: $mobile) {
                                    position: relative;
                                    transform: none;
                                    padding: 0;
                                    margin: 0;
                                    top: 0;
                                    left: 0;
                                    right: auto;
                                    &.desktop {
                                        display: none;
                                    }
                                    &.mobile {
                                        display: inherit;
                                    }
                                }
                            }
                        }
                        video {
                            @include elm_center;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                            @media only screen and (max-width: $mobile) {
                                transform: translate(-50%, -50%) scale(1.5);
                            }
                        }
                    }
                }
            }
            &__content {
                width: 60%;
                padding: 60px 80px 80px;
                transform: translate(0%, -50%);
                right: auto;
                /* Tablet layout */
                @media only screen and (min-width: $tablet_max) and (max-width: 1600px) {
                    width: 65%;
                }
                @media only screen and (min-width: $tablet_max) and (max-width: $tablet_min) {
                    width: 70%;
                }
                @media only screen and (min-width: 1000px) and (max-width: $tablet_max) {
                    width: 80%;
                }
                @media only screen and (min-width: $tablet_min) and (max-width: 1000px) {
                    width: 90%;
                }
                /* Desktop layout */
                @media screen and (max-width: $mobile){
                    width: 100%;
                    padding: 40px 20px;
                    background-color: $grey2;
                    position: relative;
                    transform: none;
                    margin-top: -10px;
                }
                .ds-hero-promo {
                    &__text{
                        &__headline {
                            color: $white;
                            @include fontsize(60);
                            font-family: Knockout;
                            font-weight: 365;
                            text-transform: uppercase;
                            line-height: 60px;
                            word-wrap: break-word;
                            margin-bottom: 20px;
                            @media screen and (max-width: $mobile) {
                                font-weight: 365;
                                text-transform: uppercase;
                                line-height: 60px;
                            }
                        }
                        &__headline, &__subheadline, &__content {
                            margin-top: 0px;
                            margin-bottom: 20px;
                        }
                        &__subheadline, &__content {
                            max-width: inherit;
                            color: $white;
                            @include fontsize(16);
                            font-family: DM Sans;
                            font-weight: 500;
                            line-height: 24px;
                            word-wrap: break-word;
                            @media only screen and (max-width: $mobile) {
                                font-weight: 400;
                            }
                        }
                        &__subheadline {
                            @include fontsize(32);
                            font-family: Knockout;
                            font-weight: 365;
                        }
                        &__content {
                            margin-bottom: 30px;
                            * {
                                margin-top: 0px;
                            }
                            &.scroll-y {
                                max-height: 100px;
                                overflow-y: scroll;
                                &::-webkit-scrollbar {
                                  width: 10px;
                                  height: 10px;
                                }
                                &::-webkit-scrollbar-track {
                                  box-shadow: inset 0 0 10px rgba(180, 180, 180, 0.3);
                                  border-radius: 5px;
                                }
                                &::-webkit-scrollbar-thumb {
                                  background-color: $grey1;
                                  border-radius: 5px;
                                }
                                @media only screen and (max-width: $mobile) {
                                    overflow-y: visible;
                                    max-height: none;
                                }
                            }
                        }
                    }
                    &__button-group {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        flex-wrap: nowrap;
                        gap: .6rem;
                        @media only screen and (max-width: $tablet_max) {
                            flex-wrap: wrap;
                        }
                        @media only screen and (max-width: $mobile) {
                            flex-direction: column;
                        }
                        .ds-btn {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            padding: 13px 24px;
                            border-radius: 30px;
                            justify-content: center;
                            align-items: center;
                            gap: 20px;
                            text-decoration: none;
                            @include fontsize(16);
                            font-family: DM Sans;
                            font-weight: 700;
                            line-height: 24px;
                            white-space: nowrap;
                            margin-right: 10px;
                            margin-bottom: 10px;
                            @media only screen and (max-width: $tablet_max) {
                                margin-bottom: 10px;
                            }
                            @media only screen and (max-width: $mobile) {
                                @include fontsize(16);
                                font-weight: 700;
                                width: fit-content;
                            }
                            svgs {
                                svg {
                                    path {
                                        stroke: $white;
                                    }
                                }
                            }
                            &-red {
                                color: $white;
                                background-color: $red;
                            }
                            &-chevron-down {
                                color: $red;
                                background-color: $white;
                                svgs {
                                    svg {
                                        path {
                                            stroke: $red;
                                        }
                                    }
                                }
                            }
                            .txt {
                                margin-right: 20px;
                                white-space: nowrap;
                                @media only screen and (max-width: $mobile) {
                                    white-space: break-spaces;
                                }
                            }
                            &:hover {
                                text-decoration: underline;
                                transform: translateY(-4px);
                                box-shadow: 0 8px 16px rgba(0,0,0,.3);
                            }
                        }
                    }
                }
            }
            .mobile-breadcrumbs {
                display: none;
                padding: 0 0 16px;
                @media only screen and (max-width: $mobile) {
                    display: inherit;
                }
                .breadcrumb {
                    padding: 0;
                }
            }
            &[version="standard"] {
                .ds-hero-promo {
                    &__text {
                        &__headline, &__subheadline, &__content {
                            text-shadow: none;
                        }
                        &__subheadline {
                            line-height: 2.25rem;
                        }
                    }
                }
                .ds-item {
                    &.ds-hero-promo {
                        &__content {
                            .ds-hero-promo {
                                &__button-group {
                                    .ds-btn {
                                        &:first-child {
                                            &:after {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                @include mobileBreadcrumbs;
            }
            &[version="white-header-and-full-image-banner"] {
                .ds-item {
                    &.ds-hero-promo {
                        &__content {
                            background-color: $white;
                            padding: 40px;
                            border-radius: 20px;
                            margin-left: 40px;
                            transform: translate(0%, -50%);
                            @media only screen and (max-width: 1700px) {
                                width: 42%;
                            }
                            @media only screen and (max-width: 1580px) {
                                width: 45%;
                            }
                            @media only screen and (min-width: $tablet_max) and (max-width: 1550px) {
                                padding: 30px;
                            }
                            @media only screen and (min-width: $mobile) and (max-width: 1020px) {
                                width: 70%;
                            }
                            @media only screen and (min-width: $mobile) and (max-width: $tablet_max) {
                                padding: 20px;
                            }
                            @media only screen and (max-width: $mobile) {
                                border-radius: unset;
                                margin: 0;
                                transform: translate(0%, 0%);
                                width: 100%;
                            }
                            .ds-hero-promo {
                                &__text {
                                    &__headline, &__subheadline {
                                        text-shadow: none;
                                    }
                                    &__headline {
                                        color: $red;
                                        @include fontsize(60);
                                        font-family: Knockout;
                                        font-weight: 365;
                                        @media only screen and (min-width: $mobile) and (max-width: $tablet_max) {
                                            @include fontsize(50);
                                        }
                                    }
                                    &__subheadline {
                                        @include fontsize(32);
                                        font-family: Knockout;
                                        font-weight: 365;
                                        @media only screen and (min-width: $mobile) and (max-width: $tablet_max) {
                                            @include fontsize(28);
                                        }
                                    }
                                    &__subheadline, &__content {
                                        color: $black;
                                    }
                                }
                                &__button-group {
                                    .ds-btn {
                                        &:first-child {
                                            &:after {
                                                display: none;
                                            }
                                        }
                                        &:nth-child(2) {
                                            color: $red;
                                            border: 1.50px $red solid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &[version="dark-header-and-small-image"] {
                background-color: $white;
                overflow: visible;
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding-bottom: 0;
                @media only screen and (max-width: $mobile) {
                    flex-direction: column;
                }
                .ds-item {
                    &.ds-hero-promo {
                        &__gradient-overlay {
                            display: none;
                        }
                        &__image {
                            padding: 0;
                            width: 520px;
                            height: auto;
                            height: 300px;
                            overflow: hidden;
                            position: relative;
                            margin: 60px 80px 0 0;
                            border-radius: 40px;
                            overflow: hidden;
                            @media only screen and (min-width: $tablet_min) and (max-width: $tablet_max) {
                                margin: 50px 20px 0 0px;
                                width: 50%;
                            }
                            @media only screen and (max-width: $mobile) {
                                width: 100%;
                                height: auto;
                                margin: 0;
                                padding: 0;
                                border-radius: 0;
                            }
                            img {
                                width: 100%;
                                max-width: none;
                                @include elm_center;
                                @media only screen and (max-width: $mobile) {
                                    position: relative;
                                    top: 0%;left: 0%;
                                    transform: none;
                                }
                                &.desktop {
                                    width: 100%;
                                }
                            }
                        }
                        &__content {
                            position: relative;
                            margin: 40px 20px 40px 80px;
                            padding: 0px;
                            width: 50%;
                            max-width: 670px;
                            transform: translate(0%, 0%);
                            @media only screen and (max-width: $mobile) {
                                background-color: $white;
                                margin: 0;
                                width: 100%;
                                padding: 40px 20px;
                            }
                            .ds-hero-promo {
                                &__text {
                                    &__headline, &__subheadline {
                                        text-shadow: none;
                                    }
                                    &__headline {
                                        color: $red;
                                    }
                                    &__subheadline, &__content {
                                        color: $black;
                                    }
                                    &__content {
                                        &.scroll-y {
                                            max-height: none;
                                            overflow-y: visible;
                                        }
                                    }
                                }
                                &__button-group {
                                    .ds-btn {
                                        &:nth-child(2) {
                                            color: $red;
                                            border: 1.50px $red solid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &[version="full-image-banner"] {
                width: 100%;
                height: auto;
                padding-bottom: 0;
                @media only screen and (max-width: $mobile) {
                    @include flex_column;
                }
                .ds-item {
                    &.ds-hero-promo {
                        &__image {
                            position: absolute;
                            height: auto;
                            padding: 0%;
                            width: 100%;
                            @media only screen and (max-width: $mobile) {
                                position: relative;
                            }
                            img {
                                position: relative;
                                transform: none;
                                top: 0;
                                left: 0;
                            }
                        }
                        &__content {
                            background-color: transparent;
                            padding: 40px;
                            border-radius: 20px;
                            margin-left: 40px;
                            position: relative;
                            @media only screen and (max-width: $mobile) {
                                background-color: $grey2;
                                margin: 0;
                                padding: 20px;
                                border-radius: 0;
                                margin-top: -10px;
                            }
                            .ds-hero-promo {
                                &__text {
                                    &__headline, &__subheadline {
                                        text-shadow: none;
                                    }
                                    &__headline {
                                        color: $white;
                                    }
                                    &__subheadline {
                                        color: $white;
                                    }
                                    &__content {
                                        &.scroll-y {
                                            max-height: none;
                                            overflow-y: visible;
                                        }
                                    }
                                }
                                &__button-group {
                                    .ds-btn {
                                        &-red {
                                            background-color: $green;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                @include mobileBreadcrumbs;
            }
        }
        &[video-container] {
            .ds-hero-promo {
                min-height: unset;
                .ds-item {
                    &.ds-hero-promo__image {
                        img {
                            &.desktop, &.mobile {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
