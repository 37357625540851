splide {
    position: relative;
    .splide {
        &__track {
            .splide__list {
                img {
                    width: 100%;
                }
            }
        }
        &__pagination{
            &__page {
                border: 1px solid $grey;
                background-color: $white;
                &.is-active {
                    background-color: $grey;
                }
            }
        }
    }
}
