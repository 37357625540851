body {
    .funnelbackSearch__wrapper {
        max-width: $ContainerMaxWidth;
        margin: auto;
        h2, h3, h4, h5, h6 {
            margin: 0;
        }
        p, ol, ul {
            margin: 0;
        }
        * {
            -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
        }
        .btn-tabs-wrapper {
            .btn-tab {

                margin-right: 10px;
                margin-bottom: 10px;
                &:not(.active) {
                    background-color: $white;
                    color: $red;
                    border: 1px solid $black;
                }
            }
        }
        .bx-loadmore--wrapper {
            @include flex_center;
            .btn-loadmore {
                margin: 40px auto;
                color: $black;
                border-color: $black;
            }
        }
        .funnelbackSearch {
            &--wrapper{
                &--group {
                    @include flex_row_wrap;
                    align-items: center;
                    .bx-item {
                        margin-right: 10px;
                    }
                }
                &--summary {
                    margin: 40px 0;
                    &--sort {
                        position: relative;
                        .bg {
                            @include bgClick;
                            display: none;
                            z-index: 1;
                            pointer-events: none;
                        }
                        .bx-title {
                            @include flex_row;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer;
                            z-index: 2;
                            position: relative;
                            border-radius: 30px;
                            border: 1px solid $black;
                            width: fit-content;
                            padding: 6px 24px;
                            background-color: $white;
                            * {
                                pointer-events: none;
                            }
                            .txt {
                                margin-right: 10px;
                                display: inline-block;
                                font-weight: bold;
                            }
                        }
                        svgs {
                            @include flex_center;
                        }
                        .sort-select {
                            &--wrapper {
                                position: absolute;
                                top: calc(100% + 2px);
                                left: 50%;
                                transform: translate(-50%, 0%);
                                border-radius: 5px;
                                border: 1px solid $black;
                                background: $white;
                                opacity: 0;
                                @include flex_column;
                                display: none;
                                z-index: 1000;
                                a {
                                    text-decoration: none;
                                }
                            }
                            &--item {
                                cursor: pointer;
                                padding: 6px 24px;
                                width: 100%;
                                color: $black;
                                text-decoration: none;
                                &:hover {
                                    background: $grey;
                                    color: $white;
                                }
                            }
                        }
                        &.transitionIn, &.transitionOut, &.active {
                            opacity: 1;
                            .sort-select--wrapper {
                                opacity: 1;
                                display: inherit;
                            }
                        }
                        &.active {
                            .bg {
                                display: inherit;
                                pointer-events: auto;
                            }
                        }
                    }
                }
                .search-results {
                    &--wrapper {
                        @include flex_row;
                        justify-content: space-between;
                        padding-bottom: 30px;
                        .search-side-wrapper {
                            @include flex_column;
                            .search-filter {
                                padding: 10px 0px;
                                .sub-title {
                                    margin-left: 10px;
                                }
                                .txt {
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                    .search-result {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
