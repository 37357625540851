.ds-btn,
.ds-btn-primary,
.ds-btn-secondary,
.ds-btn-cta,
[type=button],
[type=submit],
[type=reset] {
    border-radius: 1.875rem;
    padding: .8rem 1.5rem;

    &:hover {
        background-color: var(--latrobe-grey-1);
    }
}

@media (max-width: 768px) {
    .ds-btn,
    .ds-btn-primary,
    .ds-btn-secondary,
    .ds-btn-cta,
    [type=button],
    [type=submit],
    [type=reset] {
        width: fit-content;
    }
}

.ds-btn.ds-btn--small,
.ds-btn-primary.ds-btn--small,
.ds-btn-secondary.ds-btn--small,
.ds-btn--small[type=button],
.ds-btn--small[type=submit],
.ds-btn--small[type=reset] {
    @include fontsize(14);
    padding: .8rem 1.4rem;
}

.ds-btn-primary:hover,
[type=submit]:hover {
    background-color: var(--latrobe-red-shade-20);
}

.ds-btn-secondary:hover {
    color: var(--latrobe-red-shade-20);
    background-color: white;
    border-color: var(--latrobe-red-shade-20);

    &::after {
        filter: invert(17%) sepia(47%) saturate(5974%) hue-rotate(353deg) brightness(86%) contrast(94%);
    }

    &[class*=ds-icon-].ds-icon--before::before:not(.ds-btn--light) {
        filter: invert(17%) sepia(47%) saturate(5974%) hue-rotate(353deg) brightness(86%) contrast(94%);
    }
}

.ds-btn-primary.ds-btn--light {
    &:hover {
        background-color: white;
    }

    &:active {
        color: var(--latrobe-red-shade-20);   
    }

    &:active::after,
    &[class*=ds-icon-].ds-icon--before:active::before {
        filter: invert(17%) sepia(47%) saturate(5974%) hue-rotate(353deg) brightness(86%) contrast(94%);
    }
}

.ds-btn-secondary.ds-btn--light {
    &:hover {
        background-color: transparent;
        color: white;
        border-color: white;

        
        &:active {
            background-color: white;
            border-color: var(--latrobe-red-shade-20);
            color: var(--latrobe-red-shade-20);
        }

        &:active::after,
        &[class*=ds-icon-].ds-icon--before:active::before {
            filter: invert(17%) sepia(47%) saturate(5974%) hue-rotate(353deg) brightness(86%) contrast(94%);
        }
    }

}


.ds-btn--cta {
    background-color: var(--green);
    color: white;

    &:hover {
        background-color: var(--green-cta-shade-20);
    }
}

.ds-btn.ds-btn--small,
.ds-btn-primary.ds-btn--small,
.ds-btn-secondary.ds-btn--small {
    border-radius: 1.875rem;
    border-width: 1px;
}

// Not sure why we have to import chevron-right
[class*="ds-icon-chevron-right"]::after {
    background-image: url('../externals/icons/feather/chevron-right.svg');
}