footer {
    &[data-footer] {
        @media only screen and (min-width: $tablet_min) {
            .desktop {
                display: inherit;
            }
            .mobile {
                display: none;
            }
        }
        @media only screen and (max-width: $mobile) {
            .desktop {
                display: none;
            }
            .mobile {
                display: inherit;
            }
        }
        .footer-wrapper {
            background-color: $black;
            padding: 60px 80px 20px;
            @include flex_column;
            * {
                color :$white;
            }
            @media only screen and (max-width: $mobile) {
                padding: 30px 0px 30px;
                width: 100%;
            }
            .footer-bx  {
                @media only screen and (max-width: $mobile) {
                    padding: 0 20px;
                }
                a {
                    text-decoration: none;
                }
                .btn-donate {
                    text-decoration: none;
                    white-space: nowrap;
                }
            }
            .icon-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                @media only screen and (min-width: $tablet_min) {
                    &.desktop {
                        display: inherit;
                    }
                    &.mobile {
                        display: none;
                    }
                }
                @media only screen and (max-width: $mobile) {
                    margin-top: 20px;
                    margin-bottom: 0px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $white;
                    position: relative;
                    &.desktop {
                        display: none;
                    }
                    &.mobile {
                        display: inherit;
                        border: none;
                        @include mobileBorderBottom;
                    }
                }
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 22%;
                    margin-bottom: 15px;
                    @media only screen and (max-width: $mobile) {
                        width: fit-content;
                    }
                    &[btn-donation] {
                        @media only screen and (max-width: $mobile) {
                            display: none;
                        }
                    }
                    &:hover {
                        svgs {
                            svg {
                                fill: $red;
                                path {
                                    fill: $red;
                                }
                            }
                        }
                    }
                }
            }
            .footer-category-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                @media only screen and (max-width: $mobile) {
                    flex-direction: column;
                }
                .footer-category-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    @media only screen and (max-width: $mobile) {
                        flex-direction: column;
                        width: 100%;
                    }
                    &:first-child {
                        width: 75%;
                    }
                    &:last-child {
                        width: 20%;
                        @include flex_column;
                        justify-content: flex-start;
                        max-width: 188px;
                        @media only screen and (max-width: $mobile) {
                            width: 100%;
                            max-width: none;
                        }
                        svgs {
                            width: fit-content;
                            &[data-svg="icon-logo-white"] {
                                height: auto;
                                width: 100%;
                                border-bottom: 1px solid $white;
                                padding-bottom: 20px;
                                margin-bottom: 20px;
                                margin-top: -10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                @media only screen and (max-width: $mobile) {
                                    display: none;
                                }
                                svg {
                                    width: 170px;
                                    height: auto;
                                }
                            }
                        }
                    }
                    .cat-wrapper {
                        a {
                            text-decoration: none;
                        }
                        .title-wrapper {
                            cursor: pointer;
                            &.btn-collapse {
                                @media only screen and (min-width: $tablet_min) {
                                    pointer-events: none;
                                }
                                * {
                                    pointer-events: none;
                                }
                            }
                            @media only screen and (max-width: $mobile) {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                            }
                            .title {
                                @include fontsize(24);
                                font-weight: 365;
                                text-transform: uppercase;
                                font-family: Knockout;
                                border-bottom: 1px solid $white;
                                padding-bottom: 10px;
                                margin-bottom: 20px;
                                display: inline-block;
                                @media only screen and (max-width: $mobile) {
                                    border-bottom: none;
                                    margin-bottom: 0px;
                                    margin-right: 10px;
                                    padding: 0;
                                }
                            }
                            svgs {
                                display: none;
                                transform: rotate(90deg);
                                @media only screen and (max-width: $mobile) {
                                    display: block;
                                }
                                svg {
                                    path {
                                        stroke: $red;
                                    }
                                }
                            }
                        }
                        .data-collapse {
                            .content {
                                @media only screen and (min-width: $tablet_min) {
                                    margin-top: 0px;
                                    display: block;
                                    height: auto;
                                    overflow: visible;
                                }
                            }

                        }
                        .cat-sub {
                            * {
                                font-family: DM Sans;
                                @include fontsize(14);
                            }
                            &.item-txt {
                                margin-bottom: 10px;
                                a {
                                    @include fontsize(16);
                                    font-family: DM Sans;
                                    font-weight: 700;
                                }
                            }
                            svgs {
                                margin: 0 10px 0 5px;
                                svg {
                                    pointer-events: none;
                                    path {
                                        stroke: $red;
                                    }
                                }
                            }
                        }
                        .cat-sub {
                            &:hover {
                                svgs {
                                    margin: 0 5px 0 10px;
                                }
                            }
                        }
                    }
                }
            }
            .footer-legal-wrapper {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid $white;
                padding-bottom: 20px;
                position: relative;
                @media only screen and (max-width: $mobile) {
                    border: none;
                }
                .item-link {
                    margin-right: 20px;
                    border-bottom: 1px solid $white;
                    a {
                        &:hover {
                            border-bottom: none;
                        }
                    }
                    &:hover {
                        border-bottom: none;
                    }
                }
            }
            .footer-copyright-wrapper {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 10px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                * {
                    width: 100%;
                    max-width: none;
                    font-family: DM Sans;
                    @include fontsize(14);
                    font-weight: 400;
                    margin: 0;
                }
                .txt {
                    *:first-child {
                        display: inline-block;
                        width: fit-content;
                        &:before {
                            content: '© ';
                        }
                    }
                }
            }
            .mobile {
                @media only screen and (min-width: $tablet_min) {
                    display: none;
                }
                svgs {
                    &[data-svg="icon-logo-white"] {
                        fill: red;
                    }
                }
                &.footer-logo-wrapper {
                    @media only screen and (max-width: $mobile) {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: space-between;
                        width: 100%;
                        padding: 40px 20px 30px;
                        margin-bottom: 30px;
                        position: relative;
                        .btn {
                            margin: 0;
                        }
                        @include mobileBorderBottom;
                    }
                }
            }
        }
    }
}
