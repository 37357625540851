body {
    .ProofPoints__wrapper {
        @include flex_column;
        padding: 50px 20px;
        .ProofPoints {
            &--wrapper {
                max-width: $ContainerMaxWidth;
                margin: auto;
                img {
                    width: 100%;
                    height: 100%;
                    border: none;
                    border-radius: unset;
                }
                .title {
                    text-align:  center;
                    margin: 0px auto 50px;
                }
                &--footnote {
                    * {
                        @include fontsize(14);
                        line-height: 20px;
                        font-weight: 400;
                    }
                    ol, ul {
                        margin: 0;
                        padding: 0 0 0 16px;
                    }
                }
                &--group {
                    width: 100%;
                    margin-bottom: 30px;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 20px;
                    .bx-item {
                        @include flex_column;
                        position: relative;
                        .item {
                            &--image {
                                width: 60px;
                                height: 60px;
                                line-height: 0;
                                @include flex_center;
                                margin-bottom: 10px;
                            }
                            &--content {
                                .title {
                                    min-height: 90px;
                                    margin: 0 0 20px;
                                    text-align: left;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                    @media only screen and (max-width: $mobile) {
                        .ProofPoints{
                            &--wrapper {
                                &--group {
                                    .bx-item {
                                        background: red !important;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
                &[type="3 columns"] {
                    .ProofPoints{
                        &--wrapper {
                            &--group {
                                grid-template-columns: repeat(3, 1fr);
                            }
                        }
                    }
                }
                &[type="4 columns"] {
                    .ProofPoints{
                        &--wrapper {
                            &--group {
                                grid-template-columns: repeat(4, 1fr);
                            }
                        }
                    }
                }
                &[type="5 columns"] {
                    .ProofPoints{
                        &--wrapper {
                            &--group {
                                grid-template-columns: repeat(5, 1fr);
                            }
                        }
                    }
                }
                &[ProofPoints-container] {
                    .ProofPoints{
                        &--wrapper {
                            &--group {
                                @media only screen and (max-width: 1000px) {
                                    grid-template-columns: repeat(3, 1fr);
                                }
                                @media only screen and (max-width: 900px) {
                                    grid-template-columns: repeat(2, 1fr);
                                }
                                @media only screen and (max-width: $mobile) {
                                    grid-template-columns: 1fr;
                                    .bx-item {
                                        @include flex_center;
                                        .item {
                                            @include flex_center;
                                            margin: auto;
                                            &--image {
                                                margin: 0 auto 20px;
                                            }
                                            &--content {
                                                .title, .content {
                                                    text-align: center;
                                                }
                                                .content {
                                                    margin-bottom: 30px;
                                                }
                                            }
                                        } // End item
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
