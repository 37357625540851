body {
    .FindYourCourse__wrapper {
        @include flex_center;
        .FindYourCourse--wrapper {
            max-width: $ContainerMaxWidthList;
            width: 100%;
            padding: 70px 0;
            h2, h3, h4, h5, h6 {
                margin-top: 0px;
            }
            .title {
                text-align: center;
                margin-bottom: 32px;
            }
            .subtitle {
                @media only screen and (max-width: $mobile) {
                    width: 95%;
                    margin: auto;
                }
            }
            .FindYourCourse {
                &--form {
                    @include searchForm;
                }
            }
            .ds-link {
                &-list {
                    @media only screen and (max-width: $mobile) {
                        min-width: unset;
                        width: 90%;
                    }
                    label {
                        display: none;
                    }
                    input {
                        border: none;
                        background-color: transparent;
                        width: 100%;
                        &:focus{
                            outline: none;
                        }
                    }
                    button {
                        background: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;
                        .btn-group {
                            @include flex_row;
                            flex-wrap: nowrap;
                            padding: 18px 24px;
                            margin: 0;
                            .txt {
                                margin-right: 20px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            @import "../../_global/css/global/linklist";
        }
    }
}
