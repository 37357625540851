nav {
    header {
        &.header-nav {
            width: 100%;
            min-height: 30px;
            padding: $header_padding $header_paddingLeft;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            display: flex;
            flex-direction: row;
            background-color: $black;
            background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 80%, rgba(255,255,255,0) 100%);
            -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            * {
                -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
            }
            @media only screen and (min-width: $header_tablet_min) and (max-width: $header_tablet_max) {
                padding: $header_padding 40px;
            }
            @media only screen and (max-width: $header_mobile) {
                padding: 20px;
                background-color: $black;
            }
            .logo {
                line-height: 0;
                margin: 0;
                padding: 0;
                @include flex_center;
                * {
                    lineheight: 0;
                }
            }
            .header-search {
                position: relative;
                @include flex_row;
                justify-content: space-between;
                width: 100%;
                display: none;
                padding: 0;
                opacity: 0;
                &--form, input, button, .btn-group {
                    opacity: 0;
                }
                &--form {
                    @include searchForm;
                    margin: 0;
                    .btn-group {
                        height: 34px;
                    }
                    .search__submit {
                        .btn-group {
                            @include flex_row_nowrap;
                            &:hover {
                                transform: translateY(0px);
                            }
                        }
                    }
                }
                .btn-close {
                    position: relative;
                    svgs {
                        @include elm_center;
                    }
                }
            }
            &.transitionIn, &.search--show {
                .header-search {
                    display: inherit;
                }
                .nav-list {
                    display: none;
                }
            }
            &.transitionIn {
                .header-search {
                    opacity: 0;
                    input, button, .btn-group {
                        height: 0;
                    }
                }
            }
            &.search--show {
                .header-search {
                    display: inherit;
                    opacity: 1;
                    &--form, input, button, .btn-group {
                        opacity: 1;
                    }
                    &--form {
                        margin: 0 0 0 30px;
                    }
                    @media only screen and (max-width: $mobile) {
                        position: absolute;
                        top: calc(100% + 10px);
                        left: 0;
                        width: 100%;
                        margin: 0px;
                        &--form {
                            margin: 0px auto;
                        }
                        .btn-close {
                            position: absolute;
                            top: -35px;
                            right: 20px;
                        }
                    }
                }
                &.transitionOut {
                    .header-search {
                        opacity: 1;
                        &--form, input, button, .btn-group, [btn-close] {
                            opacity: 0;
                        }
                    }
                    .nav-list {
                        display: inherit;
                        opacity: 0;
                        width: 0;
                        &-main, &.mobile {
                            display: none;
                        }
                    }
                }
                .item-icon {
                    opacity: 0;
                }
            }
            * {
                &::selection {
                    background: $transparent;
                    text-shadow: none;
                }
                &:focus {
                    outline: none;
                }
                textarea:focus, input:focus{
                    outline: none;
                }
            }
            [btn-remove-nav] {
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgb(0, 0, 0, 0.2);
                z-index: 1;
                display: none;
            }
            .nav-list {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                opacity: 1;
                &-main {
                    display: flex;
                    flex-direction: row;
                    @media only screen and (min-width: $header_tablet_min) and (max-width: $header_tablet_max) {
                        overflow: scroll;
                    }
                    @media only screen and (max-width: $header_mobile) {
                        display: none;
                    }
                    &:first-child {
                        width: 60%;
                        justify-content: flex-start;
                        @media only screen and (min-width: $header_tablet_min) and (max-width: $header_tablet_max) {
                            width: fit-content;
                            margin: 0 2%;
                        }
                    }
                    &:last-child {
                        width: 20%;
                        justify-content: flex-end;
                        @media only screen and (min-width: $header_tablet_min) and (max-width: $header_tablet_max) {
                            width: fit-content;
                            padding-left: 20px;
                        }
                        .item {
                            &:first-child {
                                position: relative;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 50%;
                                    left: -20px;
                                    height: 20px;
                                    transform: translate(-50%, -50%);
                                    border-left: 1px solid $white;
                                }
                            }
                            &-links {
                                @media only screen and (max-width: $header_mobile) {
                                    display: none;
                                }
                            }
                            &-icon {
                                margin-right: 0;
                                margin-left: 10px;
                                display: flex;
                                flex-direction: column;
                                @media only screen and (max-width: $header_mobile) {
                                    width: fit-content;
                                }
                                &:last-child {
                                    margin-top: 5px;
                                }
                                &.icon-search {
                                    * {
                                        pointer-events: none;
                                    }
                                }
                            }
                        }
                    }
                    .item {
                        margin-right: 20px;
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        position: relative;
                        z-index: 1;
                        -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                        -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                        -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                        * {
                            text-decoration: none;
                            @include fontsize(15);
                            font-weight: 700;
                            color: $white;
                            padding: 0px;
                            margin: 0px;
                            border-bottom: 4px solid $transparent;
                            overflow: visible;
                        }
                        a {
                            pointer-events: inherit;
                        }
                        .icon-title-wrapper {
                            display: none;
                        }
                        &:hover, &.active {
                            .title {
                                border-bottom: 4px solid $red;
                            }
                        }
                        [icon-arrow-up] {
                            width: 0;
                            height: 0;
                            position: absolute;
                            bottom: -30px;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: 0;
                            border-left: 0px solid transparent;
                            border-right: 0px solid transparent;
                            border-bottom: 0px solid $white;
                            height: 0px;
                            -webkit-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                            -moz-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                            -ms-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                            transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                            @media only screen and (max-width: 1400px) {
                                bottom: -32px;
                            }
                        }
                        &.active {
                            [icon-arrow-up] {
                                opacity: 1;
                                border-left: 10px solid transparent;
                                border-right: 10px solid transparent;
                                border-bottom: 15px solid $white;
                            }
                        }
                        .nav-list-sub-wrapper {
                            display: none;
                            position: fixed;
                            width: 100%;
                            min-height: 50px;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            &-container {
                                background-color: $white;
                                width: 90%;
                                height: 0%;
                                opacity: 0;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                padding: 0px;
                                border-radius: 40px;
                                -webkit-transition: all 300ms cubic-bezier(.17,.67,.83,.67);
                                -moz-transition: all 300ms cubic-bezier(.17,.67,.83,.67);
                                -ms-transition: all 300ms cubic-bezier(.17,.67,.83,.67);
                                transition: all 300ms cubic-bezier(.17,.67,.83,.67);
                                .sub-item {
                                    margin-top: 80px;
                                    margin-left: 30px;
                                    margin-right: 40px;
                                    opacity: 0;
                                    height: 0;
                                    overflow: hidden;
                                    padding: 0;
                                    width: 22%;
                                    color: $black;
                                    &:last-child {
                                        margin-right: 0px;
                                    }
                                    a, a *, &-sub-sub {
                                        pointer-events: initial;
                                        color: $black;
                                    }
                                    .txt{
                                        color: $black;
                                        opacity: 0;
                                        width: fit-content;
                                        height: 0;
                                        overflow: hidden;
                                    }
                                    .txt, svgs, svg {
                                        -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                        -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                        -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                    }
                                    .txt {
                                        text-transform: uppercase;
                                        display: inline-block;
                                    }
                                    svgs {
                                        &[data-svg] {
                                            width: 20px;
                                            height: 20px;
                                            display: inline-block;
                                            margin-right: 5px;
                                            margin-bottom: 5px;
                                            position: relative;
                                            svg {
                                                width: 100% ;
                                                height: 100% ;
                                                path {
                                                    stroke: $red;
                                                }
                                            }
                                        }
                                    }
                                    .btn {
                                        &.btn-white-red {
                                            color: $red;
                                            @include fontsize(16);
                                            font-weight: 700;
                                            line-height: 24px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: solid 2px $red;
                                            padding: 13px 24px;
                                            border-radius: 30px;
                                            overflow: visible;
                                            &:hover {
                                                box-shadow: 0 2px 2px rgba(0,0,0,.3);
                                            }
                                            .txt {
                                                border: none;
                                                color: $red;
                                                text-transform: none;
                                                padding: 0;
                                                margin: 0 10px 0 0;
                                            }
                                            svgs {
                                                &[data-svg] {
                                                    padding: 0;
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                    &-courses {
                                        .bx-item {
                                            margin-bottom: 10px;
                                            overflow: visible;
                                            .title-sub-link {
                                                display: flex;
                                                align-items: center;
                                                h3 {
                                                    margin-right: 10px;
                                                }
                                                svgs {
                                                    display: flex;
                                                    align-items: center;
                                                    padding: 0;
                                                    margin: 4px 5px 0 0px;
                                                }
                                                &:hover {
                                                    svgs {
                                                        margin: 4px 0px 0 5px;
                                                    }
                                                }
                                            }
                                            &.bx-search {
                                                position: relative;
                                                width: 100%;
                                                form {
                                                    min-width: unset;
                                                    background-color: $lightgrey2;
                                                    border: none;
                                                    padding: 0px 0px 0px 10px;
                                                    width: 100%;
                                                    height: 30px;
                                                    position: relative;
                                                    input {
                                                        width: 100%;
                                                        color: $black;
                                                    }
                                                    [fb-clear] {
                                                        display: none;
                                                    }
                                                    button {
                                                        .btn-group {
                                                            color: $white;
                                                            background-color: $red;
                                                            border: $red 2px solid;
                                                            border-radius: 25px;
                                                            padding: 2px 20px;
                                                            @include fontsize(15);
                                                            font-weight: 700;border: none;
                                                            .icon-search {
                                                                display: none;
                                                            }
                                                            .txt {
                                                                padding: 0;
                                                                margin: 0;
                                                                color: $white;
                                                                text-decoration: none;
                                                                text-transform: none;
                                                                border: none;
                                                            }
                                                        }
                                                    }
                                                        .funnelback-suggest--wrapper {
                                                            width: 100%;
                                                            * {
                                                                width: 100%;
                                                            }
                                                            &-contents {
                                                                .item {
                                                                    &-text {
                                                                        margin: 0;
                                                                        padding: 0;
                                                                        .txt {
                                                                            margin: 0;
                                                                            padding: 0 0 0 10px;
                                                                            border: none;
                                                                            text-transform: none;
                                                                            @include fontsize(16);
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                        }



//                                                     background-color: $lightgrey2;
//                                                     border: none;
//                                                     border-top-left-radius: 20px;
//                                                     border-bottom-left-radius: 20px;
//                                                     padding: 4px 10px;
//                                                     width: 80%;
//                                                     color: $black;
                                                }
//                                                 .btn {
//                                                     &.btn-red {
//                                                         color: $white;
//                                                         background-color: $red;
//                                                         border: $red 2px solid;
//                                                         border-radius: 25px;
//                                                         padding: 2px 20px;
//                                                         @include fontsize(15);
//                                                         font-weight: 700;
//                                                         position: absolute;
//                                                         right: 0;
//                                                         &:hover {
//                                                             transform: translateY(0px);
//                                                             box-shadow: none;
//                                                             background-color: $darkred;
//                                                             border: $darkred 2px solid;
//                                                         }
//                                                     }
//                                                 }
                                            }
                                        }
                                    }
                                    &-sub {
                                        display: flex;
                                        align-items: self-end;
                                        * {
                                            @include fontsize(16);
                                            font-weight: 700;
                                        }
                                        a {
                                            pointer-events: auto;
                                            margin-right: 10px;
                                        }
                                        &:hover {
                                            svgs {
                                                margin-left: 5px;
                                                margin-right: 0px;
                                            }
                                        }
                                        &-sub {
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                        }
                        &.transitionIn, &.active {
                            .nav-list-sub-wrapper {
                                display: flex;
                                justify-content: center;
                                align-items: flex-start;
                                flex-direction: row;
                            }
                        }
                        &.transitionIn {
                            .nav-list-sub-wrapper {
                                &-container {
                                    opacity: 0;
                                    height: 0;
                                    padding: 30px;
                                    overflow: hidden;
                                    .sub-item {
                                        opacity: 0;
                                        height: 0;
                                        margin-left: 80px;
                                        margin-top: -10px;
                                        *, .txt {
                                            opacity: 0;
                                            height: 0;
                                            padding: 0;
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                        &.active {
                            .nav-list-sub-wrapper {
                                &-container {
                                    opacity: 1;
                                    height: auto;
                                    padding: 30px;
                                    .sub-item {
                                        opacity: 1;
                                        height: auto;
                                        margin-top: 0;
                                        margin-left: 0;
                                        * {
                                            opacity: 1;
                                            height: auto;
                                            width: fit-content;
                                        }
                                        .txt {
                                            border-bottom: 1px solid $black;
                                            padding-bottom: 10px;
                                            margin-bottom: 10px;
                                            width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .nav-list-main {
                overflow: visible;
                &.mobile {
                    @media only screen and (min-width: $header_tablet_min) {
                        display: none;
                    }
                    @media only screen and (max-width: $header_mobile) {
                        display: flex;
                    }
                    svgs, svg, .icon {
                        -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                        -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                        -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                    }
                    .nav-list-main-item {
                        @include flex_row;
                        align-items: center;
                        .item-icon {
                            &.icon {
                                &-hamburger {
                                    @include flex_column;
                                    margin-left: 10px;
                                    cursor: pointer;
                                    .icon {
                                        width: 24px;
                                        border-bottom: 3px solid $white;
                                        margin-bottom: 5px;
                                        pointer-events: none;
                                        -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                        -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                        -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                    }
                                }
                            }
                        }
                    }
                    .mobile-navlist-wrapper {
                        display: none;
                        margin-top: -100%;
                        .mobile-nav-list-container {
                            -webkit-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                            -moz-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                            -ms-transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                            transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                            opacity: 0;
                            .mobile-nav-list {
                                opacity: 0;
                                height: 0;
                            }
                        }
                    }
                }
            }// mobile-nav-list-main-item
            &.mobile-nav {
                $textNavPadding: 25px;
                .nav-list-main {
                    &.mobile {
                        .mobile-navlist-wrapper {
                            display: inherit;
                            margin-top: 0%;
                            .mobile-nav-list-container {
                                display: inherit;
                                position: fixed;
                                top: 0;
                                left: 0;
                                width: 100%;
                                opacity: 0;
                                height: 0;
                                display: flex;
                                flex-direction: column;
                                overflow-y: scroll;
                                background-color: $black;
                                .item-links {
                                    display: inline-block;
                                    width: 100%;
                                    width: 80%;
                                    margin-bottom: $textNavPadding;
                                    svgs {
                                        pointer-events: none;
                                    }
                                    .btn-mobile-list {
                                        width: 100%;
                                        display: inline-block;
                                        &[btn-header-nav] {
                                            * {
                                                pointer-events: none;
                                            }
                                        }
                                        *, a {
                                            width: fit-content;
                                            color: $white;
                                            @include fontsize(24);
                                            font-weight: 365;
                                            text-transform: uppercase;
                                            font-family: Knockout;
                                            text-decoration: none;
                                        }
                                    }
                                    &.item {
                                        transition: all 0.1s linear;
                                        -webkit-transition: all 0.1s linear;
                                        -moz-transition: all 0.1s linear;
                                        -o-transition: all 0.1s linear;
                                        * {
                                        transition: all 0.1s linear;
                                        -webkit-transition: all 0.1s linear;
                                        -moz-transition: all 0.1s linear;
                                        -o-transition: all 0.1s linear;
                                        }
                                        .mobile-nav-list-sub-wrapper {
                                            opacity: 0;
                                            height: 0;
                                            overflow-y: hidden;
                                            position: relative;
                                            &-container {
                                                opacity: 0;
                                                height: 0;
                                                overflow:hidden;
                                                .sub-item {
                                                    margin-top: 10px;
                                                    svgs {
                                                        margin: 0 10px 0 10px;
                                                        position: relative;
                                                        width: 14px;
                                                        height: 14px;
                                                        border: none;
                                                        * {
                                                            border: none;
                                                        }
                                                        svg {
                                                            width: 100%;
                                                            height: 100%;
                                                            path {
                                                                stroke: $red;
                                                            }
                                                        }
                                                    }
                                                    .txt {
                                                        color: $grey;
                                                        cursor: default;
                                                    }
                                                    &-sub {
                                                        margin-top: 14px;
                                                        display: flex;
                                                        flex-direction: row;
                                                        align-items: center;
                                                        * {
                                                            border: none;
                                                        }
                                                        &:hover {
                                                            svgs {
                                                                margin: 0 5px 0 15px;
                                                            }
                                                        }
                                                    }
                                                    &-courses {
                                                        .bx-item {
                                                            margin: 20px 0 0;
                                                            .title-sub-link {
                                                                display: flex;
                                                                flex-direction: row;
                                                            }
                                                            &.bx-search {
                                                                input {
                                                                    border: none;
                                                                    border-top-left-radius: 20px;
                                                                    border-bottom-left-radius: 20px;
                                                                    background-color: $lightgrey2;
                                                                    padding: 4px 20px;
                                                                    color: $black;
                                                                }
                                                                .btn-submit {
                                                                    background-color: $red;
                                                                    @include fontsize(15);
                                                                    padding: 4px 20px;
                                                                    border: 1px solid $red;
                                                                    border-radius: 25px;
                                                                    margin-left: -20px;
                                                                }
                                                            }
                                                            .title-sub-link {
                                                                &:hover {
                                                                    svgs {
                                                                        margin: 0 5px 0 15px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    &.item.transitionIn {
                                        .mobile-nav-list-sub-wrapper {
                                            opacity: 1;
                                            height: auto;
                                            overflow-y: hidden;
                                            position: relative;
                                            &-container {
                                                opacity:1;
                                                height: 0;
                                                overflow:hidden;
                                            }
                                            .sub-item {
                                                opacity: 0;
                                                height: 0;
                                                .sub-item-sub, .txt {
                                                    opacity: 0;
                                                }
                                            }
                                        }
                                    }
                                    &.item {
                                        &.active {
                                            svgs {
                                                &.icon-title-wrapper {
                                                    transform: rotate(270deg) translate(-25%, 25%);
                                                }
                                            }
                                            .mobile-nav-list-sub-wrapper {
                                                @include flex_column;
                                                overflow-y: visible;
                                                height: auto;
                                                margin-top: 0%;
                                                opacity: 1;
                                                position: relative;
                                                &-container {
                                                    opacity: 1;
                                                    height: auto;
                                                    overflow:visible;
                                                    .sub-item {
                                                        opacity: 1;
                                                        height: auto;
                                                        .txt {
                                                            opacity: 1;
                                                        }
                                                        .sub-item-sub {
                                                            opacity: 1;
                                                            height: auto;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
                &-transitionIn {
                    .nav-list-main {
                        &.mobile {
                            .mobile-navlist-wrapper {
                                .mobile-nav-list-container {
                                    opacity: 0;
                                    height: 0;
                                    .mobile-nav-list {
                                        opacity: 0;
                                        height: 0;
                                        .item-links {
                                            opacity: 0;
                                            height: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-show {
                    .nav-list-main {
                        &.mobile {
                            .item-icon {
                                &.icon {
                                    &-search {
                                        opacity: 0;
                                        pointer-events: none;
                                    }
                                    &-hamburger {
                                        .icon {
                                            &:first-child {
                                                transform: rotate(45deg) translate(0%, 180%);
                                            }
                                            &:nth-child(2) {
                                                opacity: 0;
                                            }
                                            &:last-child {
                                                transform: rotate(-45deg) translate(22%, -400%);
                                            }
                                        }
                                    }
                                }
                            }
                            .mobile-navlist-wrapper {
                                .mobile-nav-list-container {
                                    opacity: 1;
                                    width: 100%;
                                    height: 100%;
                                    .mobile-nav-list {
                                        opacity: 1;
                                        width: 100%;
                                        height: auto;
                                        background: $black;
                                        padding: $textNavPadding 0 0 15%;
                                        .item-links {
                                            opacity: 1;
                                            height: auto;
                                            &:before {
                                                border: none;
                                            }
                                            .icon-title-wrapper {
                                                display: inline-block;
                                                margin-left: 15px;
                                                width: 20px;
                                                height: 20px;
                                                transform: rotate(90deg) translate(50%, -50%);
                                                svg {
                                                    width: 100%;
                                                    height: 100%;
                                                    path {
                                                        stroke: $red;
                                                    }
                                                }
                                            }
                                        }
                                        &:last-child {
                                            padding-top: 0;
                                            .item-links {
                                                &:first-child {
                                                    border-top: 1px solid $white;
                                                    padding-top: $textNavPadding;
                                                }
                                                * {
                                                    @include fontsize(14);
                                                    text-transform: none;
                                                    font-weight: 700;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &[version="Transparent"] {
                * {
                    -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                    -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                    -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                }
            }
            &[version="Dark"] {
                background: $black;
            }
            .breadcrumbs-wrapper {
                position:  absolute;
                top: 100%;
                left: $header_paddingLeft;
                opacity: 1;
                @media only screen and (max-width: $mobile) {
                    display: none;
                }
                .breadcrumb {
                    @include flex_row;
                    .item, .item * {
                        color: $white;
                        @include fontsize(14);
                        font-family: DM Sans;
                        font-weight: 400;
                        text-decoration: underline;
                        line-height: 20px;
                    }
                    .item {
                        padding-right: 20px;
                        margin-right: 10px;
                        margin-right: 10px;
                        position: relative;
                        &::after {
                            content: " >";
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translate(-50%, -50%);
                        }
                        &.active {
                            text-decoration: none;
                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
            &.sticky {
                background-color: rgb(0, 0, 0, 0.8);
                .breadcrumbs-wrapper {
                    opacity: 0;
                    pointer-events: none;
                    z-index: -1;
                }
            }
        } // header-nav
    }
}
&.header-nav-active {
nav {
    header {
        &.header-nav {
            [btn-remove-nav] {
                display: block;
            }
            &.mobile-nav{
                &-show, &-transitionIn {
                    [btn-remove-nav] {
                        display: none;
                    }
                }
            }
        }
        }
    }
}

