body {
    .ContactCTAsection__wrapper {
        padding: 60px 0px 60px;
        &[background="white"] {
            background-color: $white;
        }
        &[background="grey"] {
            background-color: $lightgrey2;
        }
        .ContactCTAsection {
            &--wrapper {
                max-width: $ContainerMaxWidth;
                margin: 0 auto;
                h1, h2, h3, h4, h5, h6 {
                    margin: 0;
                }
                .title {
                    text-align: center;
                    margin: 0 auto 54px;
                    color: $red;
                }
                &--container {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 20px 20px;
                    &--item {
                        .bx-item {
                            margin-bottom: 14px;
                            &--image {
                                line-height: 0;
                                height: 15%;
                                text-align: center;
                                img {
                                    border: none;
                                    border-radius: 0px;
                                }
                            }
                            &--content {
                                @include flex_column;
                                justify-content: space-between;
                                height: 75%;
                                * {
                                    text-align: center;
                                }
                                &--top {
                                    margin-bottom: 10px;
                                    .item-title {
                                        text-transform: uppercase;
                                        margin: 0 auto 10px;
                                    }
                                }
                            }
                        }
                    }
                }
                &--button {
                    margin: 50px auto 0;
                    @include flex_center;
                    .ds-btn {
                        background-color: $green;
                    }
                }
                &[version="2 columns"] {
                    .ContactCTAsection--wrapper {
                        &--container {
                            grid-template-columns: repeat(2, 1fr);
                        }
                    }
                }
                &[version="3 columns"] {
                    .ContactCTAsection--wrapper {
                        &--container {
                            grid-template-columns: repeat(3, 1fr);
                        }
                    }
                }
                &[version="4 columns"] {
                    .ContactCTAsection--wrapper {
                        &--container {
                            grid-template-columns: repeat(4, 1fr);
                            &--item {
                                .bx-item {
                                    &--image {
                                        text-align: left;
                                    }
                                    &--content {
                                        * {
                                            text-align: left;
                                        }
                                        &--top {
                                            .item-title {
                                                text-transform: unset;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &[ContactCTAsection] {
                    .ContactCTAsection--wrapper {
                        &--container {
                            @media only screen and (min-width: $tablet_min) and (max-width: $tablet_max)   {
                                grid-template-columns: repeat(2, 1fr);
                            }
                            @media only screen and (max-width: $tablet_max) {
                                width: 95%;
                                margin: auto;
                            }
                            @media only screen and (max-width: $mobile) {
                                grid-template-columns: 1fr;
                            }
                        }
                    }
                    &[version="4 columns"] {
                        .ContactCTAsection--wrapper {
                            &--container {
                                @media only screen and (max-width: $tablet_max) {
                                    grid-template-columns: repeat(2, 1fr);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
