body {
    .LinkList__wrapper {
        @include flex_center;
        .LinkList {
            &--wrapper {
                max-width: $ContainerMaxWidth;
                width: 100%;
                padding: 70px 0;
                h2, h3, h4, h5, h6 {
                    margin-top: 0px;
                }
                .title {
                    text-align: center;
                    margin-bottom: 32px;
                }
                @import "../../_global/css/global/linklist";
                &--container {
                    @media only screen and (min-width: $tablet_min) and (max-width: 1400px)   {
                        width: 98%;
                        margin: auto;
                    }
                    &--image {
                        display: none;
                    }
                }
                &[LinkList] {
                    &[version="version 1"], &[version="version 2"], &[version="version 3"], &[version="version 4"] {
                        .LinkList--wrapper--container {
                            @include flex_row;
                            &--image {
                                width: 45%;
                                padding: 20px 40px 20px 0px;
                                @include flex_column;
                                justify-content: flex-start;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .ds-link-list {
                                width: 55%;
                                grid-template-columns: 1fr;
                            }
                        }
                    }
                    &[version="version 2"] {
                        .LinkList--wrapper--container {
                            flex-direction: row-reverse;
                            &--image {
                                padding: 20px 0px 20px 40px;
                            }
                        }
                    }
                    &[version="version 3"] {
                        .LinkList--wrapper--container {
                            &--image {
                                padding: 20px 40px 20px 0px;
                            }
                            .ds-link-list {
                                grid-template-columns: 1fr 1fr;
                            }
                        }
                    }
                    &[version="version 4"] {
                        .LinkList--wrapper--container {
                            flex-direction: row-reverse;
                            &--image {
                                padding: 20px 0px 20px 40px;
                            }
                            .ds-link-list {
                                grid-template-columns: 1fr 1fr;
                            }
                        }
                    }
                    &.item--wraper {
                        .LinkList--wrapper--container {
                            align-items: flex-start;
                            @media only screen and (max-width: $mobile) {
                                @include flex_column;
                                .ds-link-list, &--image {
                                    width: 95%;
                                    margin: 0 auto;
                                }
                                &--image {
                                    padding: 0;

                                }
                                .ds-link-list {
                                    grid-template-columns: 1fr;
                                }
                            }
                        }
                    }

                }
            }
        }

        /* Desktop layout */
        @media screen and (max-width: $mobile){

        }
    }
}
