body {
    .NavigationStoriesMobile__wrapper {
        @media only screen and (min-width: $tablet_min) {
            display: none;
        }
        splide {
            padding: 30px 0;
            display: block;
            .splide__track {
                .splide__list {
                    .splide__slide {
                        &--item {
                            @include flex_column;
                            justify-content: flex-start;
                            align-items: center;
                            width: 90%;
                            .bx-image {
                                width: 80px;
                                height: 80px;
                                background: linear-gradient(to right, $red, $orange);
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    width: 90%;
                                    height: auto;
                                    border-radius: 50%;
                                    border: 2px solid $white;
                                    object-fit: cover;
                                }
                            }
                            .txt {
                                text-align: center;
                                margin-top: 15px;
                                margin-bottom: 10px;
                                width: 75%;
                                @include fontsize(14);
                                font-family: DM Sans;
                                font-weight: 400;
                                line-height: 20px;
                                display: flex;
                                align-items: flex-start;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }


        /* Tablet layout */
        @media only screen and (min-width: $tablet_min) and (max-width: $tablet_max)   {

        }

        /* Desktop layout */
        @media screen and (max-width: $mobile){

        }
    }
}
