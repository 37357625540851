.global-elements-wrapper {
    &.container {
        .btn-collapse {
            background: #EEEEEE;
            width: 100%;
            max-width: none;
            padding: 10px 5px;
            position: relative;
            .icon-arrow {
                transform: rotate(180deg) translate(0%, 50%);
                position: absolute;
                right: 5px;
                top: 50%;
                pointer-events: none;
                path {
                    stroke: $black;
                }
            }
            &.active {
                .icon-arrow {
                    transform: rotate(90deg) translate(-90%, 30%);
                }
            }
        }
        .data-collapse {
            padding: 0px 0px 30px;
        }
        width: 100%;
        height: 100%;
        position: relative;
        .global-elements-colour-wrapper {
            h1, h2, h3 {
                margin-top: 0;
                margin-bottom: 20px;
            }
            h1 {
                color: #242424;
                font-size: 60px;
                font-family: Knockout;
                font-weight: 365;
                text-transform: uppercase;
                line-height: 60px;
                word-wrap: break-word;
            }
            h2 {
                color: #242424;
                font-size: 32px;
                font-family: Knockout;
                font-weight: 365;
                word-wrap: break-word;
            }
            .bx-container {
                &-wrapper {
                    margin-bottom: 40px;
                }
                .bx-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    &.border-bottom {
                        border-bottom: 1px solid #242424;
                        margin-bottom: 20px;
                    }
                    .latrobe {
                        width: 80px;
                        height: 80px;
                        border-radius: 20px;
                        position: relative;
                        &--transparent {
                            height: 5px;
                        }
                    }
                    .txt {
                        color: #242424;
                        font-size: 16px;
                        font-family: DM Sans;
                        font-weight: 400;
                        line-height: 24px;
                        word-wrap: break-word;
                        margin-left: 10px;
                        &.bold {
                            font-weight: bolder;
                        }
                        &:nth-child(2) {
                            width: 30%;
                        }
                        &:nth-child(3) {
                            width: 20%;
                        }
                        &:nth-child(4) {
                            width: 30%;
                        }
                    }
                }
            }
        }
    }
}
