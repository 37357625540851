.breadcrumb-wrapper {
    &.template {
        display: none;
    }
    @media only screen and (max-width: $mobile) {
        display: none;
    }
    .breadcrumb {
        width: 100%;
        padding: 30px 80px 10px;
        display: flex;
        flex-direction: row;
        @media only screen and (max-width: $mobile) {
            padding: 20px 20px 0;
        }
        .item, .item a {
            color: $black;
            @include fontsize(14);
            font-family: DM Sans;
            font-weight: 400;
            text-decoration: underline;
            line-height: 20px;
        }
        .item {
            padding-right: 20px;
            margin-right: 10px;
            position: relative;
            &::after {
                content: ' >';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-50%, -50%);
            }
            &.active {
                text-decoration: none;
                font-weight: 700;
                &::after {
                    content: '';
                }
            }
        }
    }
}
