body {
    .WYSIWYGcontent__wrapper {
        .WYSIWYGcontent {
            &--wrapper {
                &--container {
                    padding-bottom: 30px;
                    max-width: $ContainerMaxWidth;
                    margin: 50px auto;
                    @media only screen and (max-width: $mobile) {
                        width: 95%;
                    }
                    .bx-item {
                        &--image {
                            line-height: 0;
                            @include flex_center;
                            img {
                                border-radius: 0;
                                border: none;
                            }
                        }
                        .title, .sub-title {
                            max-width: unset;
                            width: 100%;
                        }
                        .title {
                            text-align: center;
                            margin: 1em 0 20px;
                        }
                        .sub-title {
                            margin: 10px 0 20px;
                            text-align: center;
                        }
                        &--content {
                            margin-bottom: 20px;
                            text-align: center;
                            max-width: 900px;
                            margin: 0 auto 50px;
                            * {
                                max-width: unset;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        /* Tablet layout */
        @media only screen and (min-width: $tablet_min) and (max-width: $tablet_max)   {

        }

        /* Desktop layout */
        @media screen and (max-width: $mobile){

        }
    }
}
