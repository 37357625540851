@font-face{
    font-family:"Knockout";
    font-style:normal;
    font-weight:normal;
    font-display:swap;
    src:url("../externals/fonts/knockout/Knockout-50Welterweight.woff2") format("woff2"),url("../externals/fonts/knockout/Knockout-50Welterweight.woff") format("woff")
}
@font-face{
    font-display:swap;
    font-family:"DM Sans";
    font-style:normal;
    font-weight:400;
    src:url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-regular.woff2") format("woff2"),url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-regular.woff") format("woff")
}
@font-face{
    font-display:swap;
    font-family:"DM Sans";
    font-style:italic;
    font-weight:400;
    src:url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-italic.woff2") format("woff2"),url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-italic.woff") format("woff")
}
@font-face{
    font-display:swap;
    font-family:"DM Sans";
    font-style:normal;
    font-weight:500;
    src:url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-500.woff2") format("woff2"),url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-500.woff") format("woff")
}
@font-face{
    font-display:swap;
    font-family:"DM Sans";
    font-style:italic;
    font-weight:500;
    src:url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-500italic.woff2") format("woff2"),url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-500italic.woff") format("woff")
}
@font-face{
    font-display:swap;
    font-family:"DM Sans";
    font-style:normal;
    font-weight:700;
    src:url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-700.woff2") format("woff2"),url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-700.woff") format("woff")
}
@font-face{
    font-display:swap;
    font-family:"DM Sans";
    font-style:italic;
    font-weight:700;
    src:url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-700italic.woff2") format("woff2"),url("../externals/fonts/dm-sans/dm-sans-v11-latin_latin-ext-700italic.woff") format("woff")
}
@font-face{
    font-family:"Roboto";
    font-style:normal;
    font-weight:300;
    src:local("Roboto Light"),local("Roboto-Light"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-300.woff2") format("woff2"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-300.woff") format("woff")
}
@font-face{
    font-family:"Roboto";
    font-style:italic;
    font-weight:300;
    src:local("Roboto Light Italic"),local("Roboto-LightItalic"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-300italic.woff2") format("woff2"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-300italic.woff") format("woff")
}
@font-face{
    font-family:"Roboto";
    font-style:normal;
    font-weight:400;
    src:local("Roboto"),local("Roboto-Regular"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-regular.woff2") format("woff2"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-regular.woff") format("woff")
}
@font-face{
    font-family:"Roboto";
    font-style:italic;
    font-weight:400;
    src:local("Roboto Italic"),local("Roboto-Italic"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-italic.woff2") format("woff2"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-italic.woff") format("woff")
}
@font-face{
    font-family:"Roboto";
    font-style:normal;
    font-weight:500;
    src:local("Roboto Medium"),local("Roboto-Medium"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-500.woff2") format("woff2"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-500.woff") format("woff")
}
@font-face{
    font-family:"Roboto";
    font-style:italic;
    font-weight:500;
    src:local("Roboto Medium Italic"),local("Roboto-MediumItalic"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-500italic.woff2") format("woff2"),url("../externals/fonts/roboto/roboto-v20-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-500italic.woff") format("woff")
}
@font-face{
    font-family:"Roboto Slab";
    font-style:normal;
    font-weight:300;
    src:local("Roboto Slab Light"),local("RobotoSlab-Light"),url("../externals/fonts/roboto-slab/roboto-slab-v9-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-300.woff2") format("woff2"),url("../externals/fonts/roboto-slab/roboto-slab-v9-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-300.woff") format("woff")
}
@font-face{
    font-family:"Roboto Slab";
    font-style:normal;
    font-weight:400;
    src:local("Roboto Slab Regular"),local("RobotoSlab-Regular"),url("../externals/fonts/roboto-slab/roboto-slab-v9-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-regular.woff2") format("woff2"),url("../externals/fonts/roboto-slab/roboto-slab-v9-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-regular.woff") format("woff")
}
@font-face{
    font-family:"Roboto Slab";
    font-style:normal;
    font-weight:700;
    src:local("Roboto Slab Bold"),local("RobotoSlab-Bold"),url("../externals/fonts/roboto-slab/roboto-slab-v9-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-700.woff2") format("woff2"),url("../externals/fonts/roboto-slab/roboto-slab-v9-cyrillic_latin-ext_greek_vietnamese_latin_greek-ext_cyrillic-ext-700.woff") format("woff")
}

