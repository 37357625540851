@import "../global/variables";

body {
    font-family: "DM Sans";
    @include fontsize(16);

    b, strong {
        font-weight: 700;
    }
}

h1, h2, h3, h4 {
    font-family: "Knockout";
    font-feature-settings: "ss02"; // Style Set 02, changes the '1' glyph to match print designs
}

h5, h6 {
    font-family: "DM Sans";
    font-weight: 700;
}

h1, h2 {
    text-transform: uppercase;
}

h1 {
    @include fontsize(60);
    @include lineheight(60);
}

h2 {
    @include fontsize(48);
    @include lineheight(48);
}

h3 {
    @include fontsize(32);
}

h4 {
    @include fontsize(24);
}

h5 {
    @include fontsize(16);

    + p {
      margin-bottom: calc(20px + 1rem);
    }
}

h6 {
    @include fontsize(14);
    text-transform: initial;
    //  this is already overriding CSS
}

@media (max-width: $mobile) {
    h1 {
        @include fontsize(48);
        @include lineheight(48);
        text-transform: uppercase;
    }

    h2 {
        @include fontsize(40);
        @include lineheight(40);
        text-transform: uppercase;
    }

    h3 {
        @include fontsize(32);
    }
}

small {
    @include fontsize(14);

    b, strong {
        font-weight: 700;
    }
}

// Helpers
// .alt-set {
//     font-feature-settings: "ss02";
// }

.alt-font {
    font-family: "DM Sans";
}

.all-caps {
    text-transform: uppercase;
    // what is the common default?
}

// Lists
// ul > li > ul {
//     font-weight: 700;
// }
// li:first-of-type > ul {
//     color: var(--blue-shade-40);
//     font-weight: 700;
// }

// li:has(> ul), 
// li:has(> ol) {
//     font-weight: bold;
// }


ol.lower-alpha {
    list-style-type: lower-alpha;
}

ol.upper-alpha {
    list-style-type: upper-alpha;
}

ol.lower-roman {
    list-style-type: lower-roman;
}

ol.upper-roman {
    list-style-type: upper-roman;
}