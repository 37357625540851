body {
    .CourseCardListing__wrapper {
        padding-bottom: 30px;
        max-width: $ContainerMaxWidth;
        margin: 50px auto;
        splide, listing {
            position: relative;
            .listing {
                &__slide {
                    width: 24%;
                    .bx-container {
                    width: 100%;
                        .bx-item {
                            @include flex_column;
                            width: 100%;
                            &.bx-image {
                                position: relative;
                                display: flex;
                                flex-wrap: wrap;
                                .bx-popular {
                                    z-index: 10;
                                }
                            }
                        }
                    }
                }
            }
            .splide, .listing {
                &__track {
                    padding-bottom: 0px;
                }
                &__slide {
                    padding: 0 20px;
                    position: relative;
                    @include flex_column;
                    align-items: center;
                    a {
                        text-decoration: none;
                    }
                    .bx-container {
                        @include flex_column;
                        border-radius: 20px;
                        box-shadow: 2px 2px 10px $grey;
                        width: 98%;
                        height: 98%;
                        .bx-text-wrapper {
                            padding: 0 10px;
                            h1, h2, h3, h4, h5 {
                                margin: 0.67em 0;
                            }
                        }
                        &-contents {
                            @include flex_column;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            .bx-item  {
                                &.title, &.content {
                                    text-wrap: wrap;
                                }
                                &.title {
                                    color: $black;
                                    @include fontsize(32);
                                    font-family: Knockout;
                                    font-weight: 365;
                                    text-transform: unset;
                                    line-height: 2.25rem;
                                }
                                &.content {
                                    color: $black;
                                    @include fontsize(16);
                                    font-weight: 400;
                                    line-height: 24px;
                                    margin-bottom: 20px;
                                }
                                &.bx-tags {
                                    margin-bottom: 20px;
                                    @include flex_row_wrap;
                                    [btn-tags] {
                                        cursor: pointer;
                                        * {
                                            pointer-events: none;
                                        }
                                    }
                                    .bx-tag {
                                        padding: 5px 16px;
                                        border-radius: 30px;
                                        border: 1px solid $black;
                                        margin-right: 5px;
                                        @include fontsize(14);
                                        font-family: DM Sans;
                                        font-weight: 700;
                                        min-width: 60px;
                                        width: fit-content;
                                        @include flex_center;
                                        &[data-tag="StudyFlex"] {
                                            border-color: $blue;
                                            color: $blue;
                                        }
                                        &[data-tag="CSP"] {
                                            border-color: $pink;
                                            color: $pink;
                                        }
                                    }
                                }
                                &.bx-image {
                                    position: relative;
                                    margin-bottom: 10px;
                                    img {
                                        border-top-left-radius: 20px;
                                        border-top-right-radius: 20px;
                                        border-bottom-left-radius: unset;
                                        border-bottom-right-radius: unset;
                                    }
                                    .bx-popular {
                                        background-color: $yellow;
                                        width: fit-content;
                                        padding: 9px 24px;
                                        border-radius: 30px;
                                        position: absolute;
                                        bottom: -18px;
                                        left: 10px;
                                        @include flex_row;
                                        z-index: 10;
                                        align-items: center;
                                        .txt {
                                            color: $black;
                                            @include fontsize(14);
                                            font-family: DM Sans;
                                            font-weight: 700;
                                        }
                                        svgs {
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                        }
                        .btn-link {
                            display: inline-block;
                            padding-bottom: 40px;
                            svgs {
                                width: 8px;
                                height: 8px;
                                margin: 0 10px 0 2px;
                                * {
                                    -webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                    -moz-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                    -ms-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.05);
                                }
                                svg {
                                path {
                                    stroke: $red;
                                }
                                }
                            }
                            &:hover {
                                svgs {
                                    margin: 0 2px 0 10px;
                                }
                            }
                        }
                        &.hover {
                            .btn-link {
                                text-decoration: none;
                            }
                        }
                    }
                }
                &__pagination{
                    display: none;
                    &__page {
                        border: 1px solid $grey;
                        background-color: $white;
                        &.is-active {
                            background-color: $grey;
                        }
                    }
                }
            }
        }
        listing {
            @include flex_row_wrap;
            justify-content: flex-start;
            @media only screen and (max-width: $tablet_max) {
                justify-content: space-between;
                width: 98%;
                margin: auto;
            }
            @media only screen and (max-width: 900px) {
                justify-content: space-evenly;
                width: 100%;
            }
            @media only screen and (max-width: $mobile) {
                justify-content: center;
            }
            .listing {
                &__slide, &__slide .bx-container {
                    min-width: 298px;
                    @media only screen and (max-width: $tablet_min) {
                        min-width: none;
                        width: 50%;
                    }
                    @media only screen and (max-width: $mobile) {
                        width: 100%;
                    }
                }
            }
        }
        &[version="b"] {
            splide, listing {
                .bx-container {
                    box-shadow: none;
                    .bx-text-wrapper {
                        background-color: $white;
                        border-radius: 20px;
                        padding: 20px;
                        box-shadow: 2px 2px 10px $grey;
                        margin-top: -30px;
                        transform: translate(15px, 0%);
                        z-index: 1;
                    }
                }
            }
        }
        &[version="float"] {
            splide, listing {
                .bx-container {
                    box-shadow: none;
                    .bx-item {
                        &.bx-image {
                            img {
                                border-top-left-radius: 10px;
                                border-top-right-radius: 10px;
                                border-bottom-left-radius: 10px;
                                border-bottom-right-radius: 10px;
                            }
                        }
                    }
                }
            }
        }
        &[version="border"] {
            splide, listing {
                .bx-container {
                    border: 1px solid $black;
                    box-shadow: none;
                }
            }
        }
        &[version="noBorder"] {
            splide, listing {
                .bx-container {
                    box-shadow: none;
                }
            }
        }
        .btn-find-out-more {
            @include flex_center;
            a {
                text-decoration: none;
            }
        }
    }
}
